<template>
  <div>
    <el-upload
      :action="uploadUrl"
      :before-upload="handleBeforeUpload"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      name="file"
      :show-file-list="false"
      :headers="headers"
      style="display: none"
      ref="upload"
      v-if="this.type == 'url'"
    >
    </el-upload>
    <div class="editor" ref="editor" :style="styles"></div>
  </div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { getToken } from "@/utils/auth";
const titleConfig = {
  "ql-bold": "加粗",
  "ql-color": "颜色",
  "ql-font": "字体",
  "ql-code": "插入代码",
  "ql-italic": "斜体",
  "ql-link": "添加链接",
  "ql-background": "颜色",
  "ql-size": "字体大小",
  "ql-strike": "删除线",
  "ql-script": "上标/下标",
  "ql-underline": "下划线",
  "ql-blockquote": "引用",
  "ql-header": "标题",
  "ql-indent": "缩进",
  "ql-list": "列表",
  "ql-align": "文本对齐",
  "ql-direction": "文本方向",
  "ql-code-block": "代码块",
  "ql-formula": "公式",
  "ql-image": "图片",
  "ql-video": "视频",
  "ql-clean": "清除字体样式",
  "ql-upload": "文件",
  "ql-table": "插入表格",
  "ql-table-insert-row": "插入行",
  "ql-table-insert-column": "插入列",
  "ql-table-delete-row": "删除行",
  "ql-table-delete-column": "删除列",
};
export default {
  name: "Editor",
  props: {
    content: {
      type: String,
      default: "",
    },
    /* 编辑器的内容 */
    value: {
      type: String,
      default: "",
    },
    /* 高度 */
    height: {
      type: Number,
      default: null,
    },
    /* 最小高度 */
    minHeight: {
      type: Number,
      default: null,
    },
    /* 只读 */
    readOnly: {
      type: Boolean,
      default: false,
    },
    // 上传文件大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    /* 类型（base64格式、url格式） */
    type: {
      type: String,
      default: "url",
    },
  },
  data() {
    return {
      uploadUrl: "/financial_tech/common/upload", // 上传的图片服务器地址
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      quill: null,
      currentValue: "",
      options: {
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              [{ header: 1 }, { header: 2 }],
              [{ color: [] }, { background: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ align: [] }],
              [{ indent: "-1" }, { indent: "+1" }],
              ["link", "image", "video"],
              [
                { table: "TD" },
                { "table-insert-row": "TIR" },
                { "table-insert-column": "TIC" },
                { "table-delete-row": "TDR" },
                { "table-delete-column": "TDC" },
              ],
            ],
            handlers: {
              table: function (val) {
                this.quill.getModule("table").insertTable(2, 3);
              },
              "table-insert-row": function () {
                this.quill.getModule("table").insertRowBelow();
              },
              "table-insert-column": function () {
                this.quill.getModule("table").insertColumnRight();
              },
              "table-delete-row": function () {
                this.quill.getModule("table").deleteRow();
              },
              "table-delete-column": function () {
                this.quill.getModule("table").deleteColumn();
              },
            },
          },
          table: true,
        },
        placeholder: "",
      },
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val !== this.currentValue) {
          this.currentValue = val === null ? "" : val;
          if (this.quill) {
            console.log(this.quill, "33332222");
            this.quill.root.innerHTML = val;
            this.$emit("input", this.$refs.editor.children[0].innerHTML);
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    styles() {
      let style = {};
      if (this.minHeight) {
        style.minHeight = `${this.minHeight}px`;
      }
      if (this.height) {
        style.height = `${this.height}px`;
      }
      return style;
    },
  },
  mounted() {
    const dom = this.$el.querySelector(".editor");
    this.quill = new Quill(dom, this.options);
    // 如果设置了上传地址则自定义图片上传事件
    if (this.type == "url") {
      let toolbar = this.quill.getModule("toolbar");
      toolbar.addHandler("image", (value) => {
        this.uploadType = "image";
        if (value) {
          this.$refs.upload.$children[0].$refs.input.click();
        } else {
          this.quill.format("image", false);
        }
      });
    }
    // this.quill.setContents(this.content)
    this.quill.root.innerHTML = this.value;
    this.quill.on("text-change", () => {
      this.currentValue = this.quill.root.innerHTML;
      console.log(this.$refs.editor.children[0].innerHTML, "888");
      this.$emit("input", this.$refs.editor.children[0].innerHTML);
    });
    this.$el.querySelector(
      ".ql-table-insert-row"
    ).innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="24" height="24" viewBox="0 0 24 24"><defs><clipPath id="master_svg0_152_6483"><rect x="0" y="0" width="24" height="24" rx="0"/></clipPath></defs><g clip-path="url(#master_svg0_152_6483)"><g><path d="M10,3C10.552,3,11,3.448,11,4L11,20C11,20.552,10.552,21,10,21L4,21C3.448,21,3,20.552,3,20L3,4C3,3.448,3.448,3,4,3L10,3ZM9,5L5,5L5,19L9,19L9,5ZM18,7C20.761,7,23,9.239,23,12C23,14.761,20.761,17,18,17C15.239,17,13,14.761,13,12C13,9.239,15.239,7,18,7ZM19,9L17,9L17,10.998999999999999L15,11L15,13L17,12.999L17,15L19,15L19,12.999L21,13L21,11L19,10.998999999999999L19,9Z" fill="#444444" fill-opacity="1"/></g></g></svg>`;
    this.$el.querySelector(
      ".ql-table-insert-column"
    ).innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="24" height="24" viewBox="0 0 24 24"><defs><clipPath id="master_svg0_152_6487"><rect x="0" y="0" width="24" height="24" rx="0"/></clipPath></defs><g clip-path="url(#master_svg0_152_6487)"><g><path d="M12,13C14.761,13,17,15.239,17,18C17,20.761,14.761,23,12,23C9.239,23,7,20.761,7,18C7,15.239,9.239,13,12,13ZM13,15L11,15L11,16.999000000000002L9,17L9,19L11,18.999000000000002L11,21L13,21L13,18.999000000000002L15,19L15,17L13,16.999000000000002L13,15ZM20,3C20.552,3,21,3.448,21,4L21,10C21,10.552,20.552,11,20,11L4,11C3.448,11,3,10.552,3,10L3,4C3,3.448,3.448,3,4,3L20,3ZM5,5L5,9L19,9L19,5L5,5Z" fill="#444444" fill-opacity="1"/></g></g></svg>`;
    this.$el.querySelector(
      ".ql-table-delete-row"
    ).innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="24" height="24" viewBox="0 0 24 24"><defs><clipPath id="master_svg0_152_6479"><rect x="0" y="0" width="24" height="24" rx="0"/></clipPath></defs><g clip-path="url(#master_svg0_152_6479)"><g><path d="M20,3C20.552,3,21,3.448,21,4L21,20C21,20.552,20.552,21,20,21L14,21C13.448,21,13,20.552,13,20L13,4C13,3.448,13.448,3,14,3L20,3ZM19,5L15,5L15,19L19,19L19,5ZM6,7C8.761,7,11,9.239,11,12C11,14.761,8.761,17,6,17C3.239,17,1,14.761,1,12C1,9.239,3.239,7,6,7ZM7,9L5,9L5,10.998999999999999L3,11L3,13L5,12.999L5,15L7,15L7,12.999L9,13L9,11L7,10.998999999999999L7,9Z" fill="#444444" fill-opacity="1"/></g></g></svg>`;
    this.$el.querySelector(
      ".ql-table-delete-column"
    ).innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="24" height="24" viewBox="0 0 24 24"><defs><clipPath id="master_svg0_152_6491"><rect x="0" y="0" width="24" height="24" rx="0"/></clipPath></defs><g clip-path="url(#master_svg0_152_6491)"><g><path d="M20,13C20.552,13,21,13.448,21,14L21,20C21,20.552,20.552,21,20,21L4,21C3.448,21,3,20.552,3,20L3,14C3,13.448,3.448,13,4,13L20,13ZM19,15L5,15L5,19L19,19L19,15ZM12,1C14.761,1,17,3.239,17,6C17,8.761,14.761,11,12,11C9.239,11,7,8.761,7,6C7,3.239,9.239,1,12,1ZM13,3L11,3L11,4.9990000000000006L9,5L9,7L11,6.999L11,9L13,9L13,6.999L15,7L15,5L13,4.9990000000000006L13,3Z" fill="#444444" fill-opacity="1"/></g></g></svg>`;
    this.addQuillTitle();
  },
  activated() {
    this.quill.setContents({});
  },
  methods: {
    addQuillTitle() {
      const oToolBar = document.querySelector(".ql-toolbar");
      const aButton = oToolBar.querySelectorAll("button");
      const aSelect = oToolBar.querySelectorAll("select");
      aButton.forEach(function (item) {
        if (item.className === "ql-script") {
          item.value === "sub" ? (item.title = "下标") : (item.title = "上标");
        } else if (item.className === "ql-indent") {
          item.value === "+1"
            ? (item.title = "向右缩进")
            : (item.title = "向左缩进");
        } else {
          item.title = titleConfig[item.classList[0]];
        }
      });
      aSelect.forEach(function (item) {
        item.parentNode.title = titleConfig[item.classList[0]];
      });
    },
    getContentData() {
      return this.quill.getContents();
    },
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error({
            offset: 120,
            message: `上传文件大小不能超过 ${this.fileSize} MB!`,
          });
          return false;
        }
      }
      return true;
    },
    handleUploadSuccess(res, file) {
      // 获取富文本组件实例
      let quill = this.quill;
      // 如果上传成功
      if (res.code == 200) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  res.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", res.data.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error({
          offset: 120,
          message: "图片插入失败",
        });
      }
    },
    handleUploadError() {
      this.$message.error({
        offset: 120,
        message: "图片插入失败",
      });
    },
  },
};
</script>
<style>
/* .editor,
.ql-toolbar {
  white-space: pre-wrap !important;
  line-height: normal !important;
}
.quill-img {
  display: none;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
} */
</style>
